const faqs = [
  {
    question: " Documentación para solicitar la visa de turista de Australia",
    answer: (
      <div>
        <p>Los ciudadanos argentinos requieren visa para ingresar a Australia. El sistema de visado australiano puede ser muy sencillo, sólo debes tener en cuenta que existen diferentes tipos de visado según el objetivo (estudiar, trabajar, ambas cosas o viajar). <br/><br/> Todos los visados de Australia se solicitan online, excepto el Work and Holiday. Cada visa tiene diferentes condiciones que deberás conocer antes de presentar tu aplicación. Para solicitar un visado de viaje a Australia, por lo general, deberás aportar lo siguiente:  </p> <br/>
        <p>- Un pasaporte válido.</p>
        <p>- Un impreso de solicitud de visado cumplimentado.</p>
        <p>- Fotografías recientes tamaño carnet.</p>
        <p>- Documentos justificativos, como pruebas de su situación económica y de los arreglos de alojamiento durante su estancia en Australia.</p>
        <p>- Pago de la tasa de solicitud de visado.</p>
        <p>- Dependiendo del tipo de visado que solicites, es posible que también tengas que presentar documentación adicional o cumplir requisitos específicos, como someterte a un reconocimiento médico o presentar certificados de antecedentes penales.</p>
        <p>- Lo mejor es que consultes los requisitos específicos del tipo de visado que vas a solicitar en la página web del Ministerio del Interior del Gobierno Australiano, ya que pueden variar en función del tipo de visado.</p>
        <p>- También tendrás que cumplir ciertos requisitos financieros y médicos.</p>
        <p>- Deberás estar fuera de Australia en el momento de solicitarlo y mantener un seguro médico durante toda tu estancia en el país.</p>
      </div>
    ),
  },
  {
    question: "Tipos de visa más comunes en Australia",
    answer: (
      <div>
        <p><span className='font-bold' >- Visado de visitante (subclase 600)</span> 
 </p> <br/>
        <p>El visado de visitante le permite visitar Australia, ya sea con fines turísticos o de negocios. Está abierto a todas las nacionalidades. Por lo general, se concede un período de estancia de hasta tres meses, pero en determinadas circunstancias puede concederse hasta 12 meses. Los solicitantes tendrán que pagar una tasa para presentar su solicitud.</p> <br/>
        <p><span className='font-bold' >- Work and Holiday (subclase 462)</span> 
 </p> <br/>
        <p>Es un visado para jóvenes adultos entre los 18 y 30 años de edad que deseen trabajar y/o disfrutar de unas vacaciones en Australia durante un año y está disponible para Argentina. Es posible obtener una segunda o incluso tercera visa de trabajo y vacaciones en Australia, si cumple con los requisitos, los cuales incluyen actividades de trabajo específico en un área regional.</p>
       

      </div>
    ),
  },
  {
    question: " Asistencia al viajero en Australia",
    answer: (
      <div>
        <p>
        Asegúrate de presentar tu solicitud con suficiente antelación a la fecha de tu viaje para disponer de tiempo suficiente para su tramitación. Recuerda que al momento de ingresar al país es requisito tener y mantener un seguro de viaje adecuado durante toda tu estadía.  <br/> <br/>

Disponer de asistencia al viajero en un país extranjero es indispensable para disfrutar sin preocupaciones. 

        </p>
      </div>
    ),
  },
];

export default faqs;
